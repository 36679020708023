import { initOpenapi } from 'app/data/api/api.init'
import { useAppConfigQuery } from 'app/data/queries/app.queries'
import { ReactNode, useLayoutEffect } from 'react'

type ConfigProviderProps = { children: ReactNode }

export const AppConfigDataProvider = (props: ConfigProviderProps) => {
  const { data, isLoading, isError } = useAppConfigQuery()

  // Initialize OpenAPI
  useLayoutEffect(() => {
    if (data) initOpenapi(data)
  }, [data])

  if (isLoading) return null

  if (isError || !data) {
    throw new Error('AppConfigDataProvider: Could not load AppConfig')
  }

  return <>{props.children}</>
}
