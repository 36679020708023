import { SeverityLevel } from '@microsoft/applicationinsights-web'

// Tracker query types

export enum TrackerDataKey {
  Tracker = 'Tracker',
}

// Tracker types
export enum TrackEventType {
  ChangeLanguage = 'Change Language',
  Exception = 'Exception',
  MissingTranslation = 'Missing Translation',
  SubmitForm = 'SubmitForm',
  SubmitFormErrors = 'ErrorsOnSubmit',
}

export type TrackChangeLanguage = {
  type: TrackEventType.ChangeLanguage
  newLanguage: string
  oldLanguage: string | undefined
}

export enum TrackExceptionSeverityLevel {
  Critical = SeverityLevel.Critical,
  Error = SeverityLevel.Error,
  Warning = SeverityLevel.Warning,
  Information = SeverityLevel.Information,
}

export type TrackException = {
  type: TrackEventType.Exception
  exception: Error
  customProperties?: Record<string, any>
  severity: TrackExceptionSeverityLevel
}

export type TrackMissingTranslation = {
  key: string
  ns: string
  language: string
}

export type TrackSubmitForm = {
  type: TrackEventType.SubmitForm
  data?: Record<string, any>
}

export type TrackSubmitFormErrors = {
  type: TrackEventType.SubmitFormErrors
  errors: Array<string>
}

export type TrackEvent = TrackChangeLanguage | TrackException | TrackSubmitForm | TrackSubmitFormErrors

export type TrackEventData = {
  additionalData?: Record<string, any>
  errors?: Array<string>
  language?: string
}

export type Tracker = {
  hasAppInsightsTracking: boolean
  isInitialized: boolean
  operationId: () => string | null
  trackEvent: (payload: { name: string } & TrackEventData) => void
  trackException: (payload: Omit<TrackException, 'type'>) => void
  trackMissingTranslation: (payload: TrackMissingTranslation) => void
}

// App Insights specific types
export type AppInsights_AdditionalTrackProperties = Record<
  string,
  string | number | boolean | undefined | null
>

export type AppInsights_TrackProperties = {
  additionalTrackProps?: AppInsights_AdditionalTrackProperties
  errors?: Array<string>
  language?: string
}
