import { ReactNode, useEffect } from 'react'
import { initI18Next } from 'app/translation/i18Next.init'
import { useAppConfig } from 'app/data/app.data'

export const TranslationProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()

  useEffect(() => {
    if (appConfig.locize.productId) {
      initI18Next(appConfig)
    }
  }, [appConfig])

  return <>{props.children}</>
}
