import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import { CscViewForPartnerDTO } from 'internalDashboard/types/partner.types'
import { useDispatch } from 'react-redux'

type InternalDashboardState = {
  customer: {
    isAccountStatementDownloading: boolean
    currentCustomerId: string | null
  }
  financing: {
    currentFinancingId: number | null
    filter: {
      activeStateFilter: string
    }
  }
  partner: {
    currentPartnerId: string | null
    currentPartnerVariantId: string | null

    creditPartnerOptions: Array<CscViewForPartnerDTO>
  }
}

const initialState: InternalDashboardState | null = {
  customer: { isAccountStatementDownloading: false, currentCustomerId: null },
  financing: {
    currentFinancingId: null,
    filter: {
      activeStateFilter: 'isActive',
    },
  },
  partner: {
    currentPartnerId: null,
    currentPartnerVariantId: null,

    creditPartnerOptions: [],
  },
}

/*
 * State slice defined by the name with reducers and the initial state
 * (see https://redux-toolkit.js.org/api/createSlice)
 */

export const internalDashboardState = createSlice({
  name: 'internalDashboard',
  initialState,
  reducers: {
    setCustomerId: (state, action: PayloadAction<string | null>) => {
      state.customer.currentCustomerId = action.payload
    },
    setCustomerIsAccountStatementDownloading: (state, action: PayloadAction<boolean>) => {
      state.customer.isAccountStatementDownloading = action.payload
    },
    setFinancingId: (state, action: PayloadAction<number | null>) => {
      state.financing.currentFinancingId = action.payload
    },
    setFinacingFilter: (state, action: PayloadAction<{ activeStateFilter: string }>) => {
      state.financing.filter.activeStateFilter = action.payload.activeStateFilter
    },
    setPartnerId: (state, action: PayloadAction<string | null>) => {
      state.partner.currentPartnerId = action.payload
    },
    setPartnerVariantId: (state, action: PayloadAction<string | null>) => {
      state.partner.currentPartnerVariantId = action.payload
    },
    setCreditPartnerOptions: (state, action: PayloadAction<Array<CscViewForPartnerDTO>>) => {
      state.partner.creditPartnerOptions = action.payload
    },
  },
})

/*
 * Selectors
 */

type State = { internalDashboard: InternalDashboardState }

export const internalDashboardSelectors = {
  customer: createSelector(
    [(state: State) => state.internalDashboard],
    (internalDashboard) => internalDashboard.customer,
  ),
  financing: createSelector(
    [(state: State) => state.internalDashboard],
    (internalDashboard) => internalDashboard.financing,
  ),
  financingFilter: createSelector(
    [(state: State) => state.internalDashboard],
    (internalDashboard) => internalDashboard.financing.filter,
  ),
  partner: createSelector(
    [(state: State) => state.internalDashboard],
    (internalDashboard) => internalDashboard.partner,
  ),
}

/*
 * Actions
 */

export const internalDashboardActions = (dispatch: Dispatch) => ({
  setCustomerId: (values: string | null) =>
    dispatch(internalDashboardState.actions.setCustomerId(values)),
  setCustomerIsAccountStatementDownloading: (values: boolean) =>
    dispatch(internalDashboardState.actions.setCustomerIsAccountStatementDownloading(values)),
  setFinancingId: (values: number | null) =>
    dispatch(internalDashboardState.actions.setFinancingId(values)),
  setFinancingFilter: (values: { activeStateFilter: string }) =>
    dispatch(internalDashboardState.actions.setFinacingFilter(values)),
  setPartnerId: (values: string | null) => dispatch(internalDashboardState.actions.setPartnerId(values)),
  setPartnerVariantId: (values: string | null) =>
    dispatch(internalDashboardState.actions.setPartnerVariantId(values)),
  setCreditPartnerOptions: (values: Array<CscViewForPartnerDTO>) =>
    dispatch(internalDashboardState.actions.setCreditPartnerOptions(values)),
})

// 👉 Preferred way to use actions
export const useInternalDashboardActions = () => {
  const dispatch = useDispatch()
  return internalDashboardActions(dispatch)
}
