import { useAppConfig } from 'app/data/app.data'
import { useUserQuery } from 'app/data/queries/app.queries'
import React from 'react'

export const UserDataProvider = (props: { children: React.ReactNode }) => {
  const { appConfig } = useAppConfig()

  const { isLoading, isError } = useUserQuery(appConfig)

  if (isLoading) return null
  if (isError) return null

  return <>{props.children}</>
}
