import { AppConfig } from 'app/types/app.types'
import { OpenAPI as AppService } from './openapi'

const getSessionToken = async () => {
  return Promise.resolve(window.localStorage.getItem('token') || 'no-session')
}

export const initOpenapi = (appConfig: AppConfig) => {
  AppService.TOKEN = getSessionToken
  AppService.BASE = `${appConfig.app.dashboardWebAppUrl}${appConfig.api.appServicePath}`
}
