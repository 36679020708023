import { useAppConfig } from 'app/data/app.data'
import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { MediaQuery } from 'frr-web/lib/theme/configure.theme'
import styled from 'styled-components'

export const LoginPage = (props: { onLogin: () => void }) => {
  const { appConfig } = useAppConfig()

  return (
    <Container>
      <LeftSection>
        <BobImageWrapper>
          <BobImage src="/bob-login-laptop-2.png" />
        </BobImageWrapper>
      </LeftSection>
      <RightSection>
        <LoginForm>
          <LogoWrapper>
            <LogoTitle src={'/logo-with-text.svg'} />
          </LogoWrapper>
          <DescriptionWrapper>
            <Description>Welcome!</Description>
            <Subdescription>
              <p>
                This is a secure environment for bob Finance partners and employees. If you have trouble
                logging in, please contact <a href="mailto: help@bob.ch">help@bob.ch</a>.
              </p>
              <p>
                Clicking on the login button below confirms that you have read and consent to bob
                Finance's{' '}
                <a href="https://bob.ch/en/privacy-information/" target="_blank" rel="noreferrer">
                  Data Privacy Policy
                </a>
                .
              </p>
              <p>
                This confirmation also indicates consent to the use of technically necessary cookies on
                this website.
              </p>
            </Subdescription>
          </DescriptionWrapper>

          <ButtonWrapper>
            <Button
              data-test-id="login-azure-ad"
              label="Log in"
              type={ButtonType.Primary}
              onClick={() => {
                props.onLogin()
              }}
            />
          </ButtonWrapper>
        </LoginForm>
      </RightSection>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
`

const LeftSection = styled.div`
  background: rgb(2, 96, 117);
  background: linear-gradient(
    90deg,
    rgba(2, 96, 117, 1) 0%,
    rgba(3, 191, 233, 1) 47%,
    rgba(183, 241, 255, 1) 100%
  );
  width: 100%;

  @media ${MediaQuery.Mobile} {
    width: 0;
  }
`

const RightSection = styled.div`
  display: flex;
  width: 768px;
`

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 32px;
  justify-content: space-between;
`
const LogoWrapper = styled.div``

const LogoTitle = styled.img`
  width: 300px;
  color: var(--color-primary);
`
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  margin-right: 36px;
`

const Description = styled.p`
  margin: 10px 0 16px 0;
  font-size: 24px;
  font-weight: bold;
`

const Subdescription = styled.div`
  margin-bottom: 32px;
  font-size: 14px;
  color: var(--color-primary);
  opacity: 0.8;

  p {
    margin-bottom: 8px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  margin-right: 36px;
  margin-bottom: 240px;
`

const BobImageWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 30%;
`

const BobImage = styled.img`
  width: 50%;
  max-heihgt: 100%;
  filter: drop-shadow(5px 5px 5px #222);

  /* opacity: 1;
  animation-name: fadeBobIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;

  @keyframes fadeBobIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    60% {
      transform: scale(1.1);
    }
    80% {
      transform: scale(0.9);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  } */
`
