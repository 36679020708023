import React, { ReactNode, createContext, useCallback, useEffect, useMemo } from 'react'
import {
  MsalProvider as MsalProviderLib,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import { useMsal, useAccount } from '@azure/msal-react'
import { AccountInfo } from '@azure/msal-browser'
import { useAppConfig } from 'app/data/app.data'
import { LoginPage } from '../pages/LoginPage'

enum ScopesEnum {
  OPEN_ID = 'openid',
  USER_READ = 'user.read',
  ALL = 'api://04269503-a311-4d14-90ec-d486ff2413f8/all',
}

export const Scopes = [ScopesEnum.ALL, ScopesEnum.USER_READ, ScopesEnum.ALL]

const AzureTokenProvider = (props: { children: ReactNode }) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  const [hasToken, setHasToken] = React.useState<boolean>()

  const saveToken = useCallback(
    async (a: AccountInfo | null) => {
      if (a) {
        try {
          const t = await instance.acquireTokenSilent({
            scopes: Scopes,
            account: a,
          })
          window.localStorage.setItem('token', t.accessToken)

          setHasToken(true)
        } catch (err) {
          if (err instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect({ scopes: Scopes, account: a })
          }
          console.error(err)
          setHasToken(false)
        }
      }
    },
    [instance],
  )

  useEffect(() => {
    saveToken(account)
  }, [account, saveToken])

  if (!account || hasToken === undefined) {
    return null
  }

  return <>{props.children}</>
}
type LogoutContextType = { logout: () => void }

const LogoutContext = createContext<LogoutContextType>({ logout: () => {} })
LogoutContext.displayName = 'Logout Context'

export const useLogout = (): LogoutContextType => {
  const { instance } = useMsal()
  const logout = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(instance.getActiveAccount()?.homeAccountId as string),
      postLogoutRedirectUri: '/',
    }
    instance.logoutRedirect(logoutRequest)
  }

  return { logout }
}

export const AuthenticationProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()

  const msalInstance = useMemo(() => {
    const msalConfig = {
      auth: {
        clientId: appConfig.azure.clientId,
        authority: `https://login.microsoftonline.com/${appConfig.azure.tenantId}`,
        redirectUri: appConfig.app.dashboardWebAppUrl,
        postLogoutRedirectUri: appConfig.app.dashboardWebAppUrl,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    }

    const instance = new PublicClientApplication(msalConfig)
    instance.initialize()

    return instance
  }, [appConfig.azure.tenantId, appConfig.azure.clientId, appConfig.app.dashboardWebAppUrl])

  return (
    <MsalProviderLib instance={msalInstance}>
      <AuthenticatedTemplate>
        <AzureTokenProvider>{props.children}</AzureTokenProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <LoginPage
          onLogin={() => {
            msalInstance.loginRedirect({ scopes: Scopes })
          }}
        />
      </UnauthenticatedTemplate>
    </MsalProviderLib>
  )
}
