import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { appState } from 'app/data/redux/appState.redux'
import { internalDashboardState } from 'internalDashboard/data/redux/internalDashboardState.redux'

const reducer = combineReducers({
  app: appState.reducer,
  internalDashboard: internalDashboardState.reducer,
})

export const appStore = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [appState.actions.setTracker.type],
        // Ignore these field paths in all actions
        ignoredActionPaths: [],
        // Ignore these paths in the state
        ignoredPaths: ['app.tracker'],
      },
    }),
})
