import { useTranslation } from 'react-i18next'

export const getFallbackUserLanguage = () => {
  const userLanguage = window.localStorage.getItem('userLanguage')

  return userLanguage === null ? 'de' : userLanguage
}

export const useUserLanguage = () => {
  const { i18n } = useTranslation()

  const setUserLanguage = (language: string) => {
    i18n.changeLanguage(language)
    window.localStorage.setItem('userLanguage', language)
  }

  return {
    userLanguage: i18n.language,
    setUserLanguage,
  }
}
