import { TranslationNamespace } from 'app/types/translation.types'
import { Loading } from 'frr-web/lib/components/Loading'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

type Props = {
  mode?: 'dark' | 'default' | 'transparent'
  animated?: boolean
  zIndex?: number
  style?: React.CSSProperties
  className?: string
  showLoadingText?: boolean
}

export const LoadingPage = ({
  animated = false,
  className,
  mode = 'default',
  showLoadingText,
  style,
  zIndex,
}: Props) => {
  const { t } = useTranslation()
  return (
    <>
      {showLoadingText !== false ? (
        <LoadingPageWrapper
          $animated={animated}
          $mode={mode}
          $zIndex={zIndex}
          className={className ? `${className} animated` : 'animated'}
        >
          {showLoadingText && <LoadingText>{t('page.loadingText')}</LoadingText>}
          <Loading style={{ transform: 'scale(0.3)', ...style }} />
        </LoadingPageWrapper>
      ) : (
        <ErrorWrapper>
          <LoadingText>Something went wrong</LoadingText>
        </ErrorWrapper>
      )}
    </>
  )
}

const LoadingPageWrapper = styled.div<{
  $animated: boolean
  $mode: 'dark' | 'transparent' | 'default'
  $zIndex?: number
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ $mode }) =>
    ($mode === 'dark' && 'rgba(0, 0, 0, 0.8)') ||
    ($mode === 'transparent' && 'transparent') ||
    'rgba(255, 255, 255, 1)'};
  z-index: ${({ $zIndex }) => $zIndex ?? 999};

  ${({ $animated }) =>
    $animated
      ? css`
          animation: fadeIn 0.7s;
        `
      : ''}

  .lds-spinner div:after {
    background: ${({ $mode }) => ($mode === 'dark' ? 'white' : 'black')};
  }
`

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: 'rgba(255, 255, 255, 0.5)';
`

const LoadingText = styled.p`
  margin: 10px;
  display: flex;
`
