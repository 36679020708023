import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { appSelectors } from 'app/data/redux/appState.redux'
import { TrackExceptionSeverityLevel } from 'app/types/tracker.types'
import { Suspense, useState } from 'react'
import { Provider, useSelector } from 'react-redux'
import { appStore } from '../data/redux/appStore.redux'
import { UserContextAndPermissionsProvider } from './context/UserContextAndPermissions.context'
import { AppConfigDataProvider } from './providers/AppConfigData.provider'
import { AuthenticationProvider } from './providers/Authentication.provider'
import { ErrorBoundaryProvider } from './providers/ErrorBoundary.provider'
import { Notification, NotificationProvider } from './providers/Notification.provider'
import { ThemeProvider } from './providers/Theme.provider'
import { TrackingProvider } from './providers/Tracking.provider'
import { TranslationProvider } from './providers/TranslationProvider'
import { UserContextRouterProvider } from './providers/UserContextRouter.provider'
import { UserDataProvider } from './providers/UserData.provider'
import { LocalStorageStoreProvider } from 'internalDashboard/data/hooks/useLocalStorage'

const TrackedQueryClientProvider = ({ children }: { children: React.ReactNode }) => {
  const tracker = useSelector(appSelectors.tracker)

  const [queryClient] = useState(
    new QueryClient({
      // TODO: Try out some good default error handling and add auto AppInsights tracking
      defaultOptions: {
        queries: {
          retry: 0,
          retryDelay: 3000,
          refetchOnWindowFocus: false,
        },
      },
      queryCache: new QueryCache({
        // onSuccess(data, query, ...rest) {
        //   console.log('QUERYCACHE onSuccess', { data, query, rest })
        // },
        /* EXAMPLE ERROR
        {
    "error": {},
    "query": {
        "abortSignalConsumed": false,
        "options": {
            "retry": 3,
            "retryDelay": 3000,
            "queryKey": [
                "Config"
            ],
            "refetchOnWindowFocus": false,
            "_defaulted": true,
            "queryHash": "[\"Config\"]",
            "refetchOnReconnect": true,
            "useErrorBoundary": false,
            "_optimisticResults": "optimistic"
        },
        "cacheTime": 300000,
        "observers": [],
        "cache": {
            "listeners": {},
            "config": {},
            "queries": [],
            "queriesMap": {}
        },
        "logger": {
            "memory": {}
        },
        "queryKey": [
            "Config"
        ],
        "queryHash": "[\"Config\"]",
        "initialState": {
            "dataUpdateCount": 0,
            "dataUpdatedAt": 0,
            "error": null,
            "errorUpdateCount": 0,
            "errorUpdatedAt": 0,
            "fetchFailureCount": 0,
            "fetchFailureReason": null,
            "fetchMeta": null,
            "isInvalidated": false,
            "status": "loading",
            "fetchStatus": "idle"
        },
        "state": {
            "dataUpdateCount": 0,
            "dataUpdatedAt": 0,
            "error": {},
            "errorUpdateCount": 1,
            "errorUpdatedAt": 1695302354586,
            "fetchFailureCount": 4,
            "fetchFailureReason": {},
            "fetchMeta": null,
            "isInvalidated": false,
            "status": "error",
            "fetchStatus": "idle"
        },
        "revertState": {
            "dataUpdateCount": 0,
            "dataUpdatedAt": 0,
            "error": null,
            "errorUpdateCount": 0,
            "errorUpdatedAt": 0,
            "fetchFailureCount": 0,
            "fetchFailureReason": null,
            "fetchMeta": null,
            "isInvalidated": false,
            "status": "loading",
            "fetchStatus": "idle"
        },
        "retryer": {
            "promise": {}
        },
        "promise": {},
        "isFetchingOptimistic": false
    },
    "rest": []
}

        */
        onError: (error: any, _query, ..._rest) => {
          /**
           * Removing token and refreshing causes a logout and excecution of the saveToken function in the AuthenticationProvider
           * This is done to prevent blank screen after the access token expires
           */
          if ('status' in error && error.status === 401) {
            window.localStorage.removeItem('token')
            window.location.reload()
          }
        },
      }),
      mutationCache: new MutationCache({
        onMutate: (_variables, mutation) => {
          if (mutation.options.meta?.loadingMessage) {
            Notification.info(String(mutation.options.meta.loadingMessage), { isTextTranslated: true })
          }
        },
        onSuccess: (_data, _variables, _context, mutation) => {
          if (mutation.options.meta?.successMessage) {
            Notification.dismiss()
            Notification.success(String(mutation.options.meta.successMessage), {
              isTextTranslated: true,
            })
          }
        },
        onError: (error, _variables, _context, mutation) => {
          if (mutation.options.meta?.errorMessage) {
            Notification.dismiss()
            Notification.error(String(mutation.options.meta.errorMessage), { isTextTranslated: true })
          }

          tracker.trackException({
            exception: error as Error,
            customProperties: {},
            severity: TrackExceptionSeverityLevel.Error,
          })
        },
      }),
    }),
  )

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export const App = () => {
  return (
    <Suspense fallback={null}>
      <Provider store={appStore}>
        <TrackedQueryClientProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider>
            <ErrorBoundaryProvider>
              <NotificationProvider>
                <AppConfigDataProvider>
                  <TrackingProvider>
                    <TranslationProvider>
                      <AuthenticationProvider>
                        <UserDataProvider>
                          <UserContextAndPermissionsProvider>
                            <LocalStorageStoreProvider>
                              <UserContextRouterProvider />
                            </LocalStorageStoreProvider>
                          </UserContextAndPermissionsProvider>
                        </UserDataProvider>
                      </AuthenticationProvider>
                    </TranslationProvider>
                  </TrackingProvider>
                </AppConfigDataProvider>
              </NotificationProvider>
            </ErrorBoundaryProvider>
          </ThemeProvider>
        </TrackedQueryClientProvider>
      </Provider>
    </Suspense>
  )
}
