import React, { useCallback, useContext, useMemo, useState } from 'react'

const getStorageValue = <T,>(key: string, defaultValue: T) => {
  // getting stored value
  const saved = window.localStorage.getItem(key)
  return saved ? JSON.parse(saved) : defaultValue
}

type Store = {
  values: Record<string, unknown>
  setValues: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
}

const localStorageContext = React.createContext<Store>({
  values: {},
  setValues: () => {
    throw new Error('no context')
  },
})

export const useLocalStorage = <T,>(key: string, defaultValue: T) => {
  const { values, setValues } = useContext(localStorageContext)

  const value = (values[key] ?? getStorageValue(key, defaultValue)) as T
  const setValue = useCallback(
    (newValue: T | ((oldValue: T) => T)) => {
      if (typeof newValue === 'function') {
        setValues((prev) => ({ ...prev, [key]: (newValue as (oldValue: T) => T)(prev[key] as T) }))
        window.localStorage.setItem(key, JSON.stringify(newValue))
      } else {
        setValues((prev) => ({ ...prev, [key]: newValue }))
        window.localStorage.setItem(key, JSON.stringify(newValue))
      }
    },
    [key, setValues],
  )

  return useMemo(() => [value, setValue] as const, [value, setValue])
}

export const LocalStorageStoreProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [values, setValues] = useState<Record<string | number, unknown>>({})

  const value = useMemo(() => ({ values, setValues }), [values, setValues])

  return <localStorageContext.Provider value={value}>{children}</localStorageContext.Provider>
}
