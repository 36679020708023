import { i18n } from 'app/translation/i18Next.init'
import { TranslationNamespace } from 'app/types/translation.types'
import { ReactNode } from 'react'
import { Slide, ToastContainer, ToastOptions, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export type NotificationOptions = ToastOptions & {
  isTextTranslated?: boolean
  preventAutoClose?: boolean
  translationNamespace?: TranslationNamespace
}

const DefaultOptions: NotificationOptions & ToastOptions = {
  position: 'top-center',
  isTextTranslated: false,
  pauseOnFocusLoss: false,
  preventAutoClose: false,
  transition: Slide,
}

export const Notification = {
  info: (text: string, options: NotificationOptions = DefaultOptions) => {
    const tOptions = { ns: options.translationNamespace }
    let toastText = options.isTextTranslated ? text : `${i18n.t(text, tOptions)}`

    // If no translation exists in locize, show a general loading message
    if (!options.isTextTranslated && toastText === text) {
      toastText = i18n.t('notification.general.loading', tOptions)
      console.log('🧩 Missing translation for LOADING message', { text, options })
    }

    toast.info(toastText, {
      autoClose: options.preventAutoClose ? false : 3000,
      ...options,
    })
  },
  success: (text: string, options: NotificationOptions = DefaultOptions) => {
    toast.dismiss()

    const tOptions = { ns: options.translationNamespace }
    let toastText = options.isTextTranslated ? text : `${i18n.t(text, tOptions)}`

    // If no translation exists in locize, show a general success message
    if (!options.isTextTranslated && toastText === text) {
      toastText = i18n.t('notification.general.success', tOptions)
      console.log('🧩 Missing translation for SUCCESS message', { text, options })
    }

    toast.success(toastText, {
      autoClose: options.preventAutoClose ? false : 3000,
      ...options,
    })
  },
  error: (text: string, options: NotificationOptions = DefaultOptions) => {
    toast.dismiss()

    const tOptions = { ns: options.translationNamespace }
    let toastText = options.isTextTranslated ? text : `${i18n.t(text, tOptions)}`

    // If no translation exists in locize, show a general error message
    if (!options.isTextTranslated && toastText === text) {
      toastText = i18n.t('notification.general.error', tOptions)
      console.log('🧩 Missing translation for ERROR message', { text, options })
    }

    toast.error(toastText, {
      autoClose: options.preventAutoClose ? false : 6000,
      ...options,
    })
  },
  warning: (text: string, options: NotificationOptions = DefaultOptions) => {
    toast.dismiss()

    const tOptions = { ns: options.translationNamespace }
    let toastText = options.isTextTranslated ? text : `${i18n.t(text, tOptions)}`

    // If no translation exists in locize, show a general error message
    if (!options.isTextTranslated && toastText === text) {
      toastText = i18n.t('notification.general.warning', tOptions)
      console.log('🧩 Missing translation for WARNING message', { text, options })
    }

    toast.error(toastText, {
      autoClose: options.preventAutoClose ? false : 6000,
      ...options,
    })
  },
  dismiss: toast.dismiss,
}

export const NotificationProvider = (props: { children: ReactNode }) => {
  return (
    <>
      <ToastContainer limit={1} position="top-center" />
      {props.children}
    </>
  )
}
