import { AppConfig } from 'app/types/app.types'

import { MeDTO } from './openapi'
import { UserDTO } from 'app/types/user.types'

export const mapMeToUser = (params: { config: AppConfig; me: MeDTO }): UserDTO => {
  const user: UserDTO = {
    ...params.me,
    permissions: params.me.permissions.map((p) => params.config.azure.userPermissionGroups[p]),
  }

  return user
}
