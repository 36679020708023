import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { DataView, Pagination, ViewOverlay } from 'app/types/app.types'
import { Tracker } from 'app/types/tracker.types'
import {
  UserPermissions,
  UserSettingOpenPartnerFinancingMode,
  UserSettings,
  UserSettingsAppMode,
  UserSettingsViewOption,
  UserViewContext,
} from 'app/types/user.types'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

const USER_SETTINGS_KEY = 'userSettings'

type AppState = {
  dataView: DataView
  isEditForm: boolean
  openOverlays: Array<ViewOverlay>
  showLoadingAnimation: boolean
  tracker: Tracker
  userPermissions: UserPermissions
  userSettings: UserSettings
  userViewContext: UserViewContext
}

const InitialTracker: Tracker = {
  hasAppInsightsTracking: false,
  isInitialized: false,
  operationId: () => null,
  trackEvent: () => null,
  trackException: () => null,
  trackMissingTranslation: () => null,
}

const InitialUserPermissions: UserPermissions = {
  hasCustomerImpersonationPermission: false,
  hasCustomerUpdatePermission: false,
  hasDeveloperPermission: false,
  hasFinancingCancelContractPermission: false,
  hasFinancingCreditScoringPermission: false,
  hasFinancingListPermission: false,
  hasFinancingWithdrawApplicationPermission: false,
  hasPartnerCreatePermission: false,
  hasPartnerListPermission: false,
  hasPartnerUpdatePermission: false,
  hasSupportPermission: false,
}

const getInitialUserSettings = (): UserSettings => {
  let initialUserSettings: UserSettings = {
    appMode: UserSettingsAppMode.Live,
    appModeBetaViewOption: UserSettingsViewOption.Option4,
    financingDetailAppMode: UserSettingsAppMode.Live,
    openPartnerFinancingMode: UserSettingOpenPartnerFinancingMode.NewTab,
  }

  try {
    const localeStorageUserSettings = window.localStorage.getItem(USER_SETTINGS_KEY)
    if (localeStorageUserSettings) {
      initialUserSettings = JSON.parse(localeStorageUserSettings)
    }
  } catch (e) {}

  return initialUserSettings
}

const setInitialUserSettings = (userSettings: UserSettings) => {
  try {
    window.localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(userSettings))
  } catch (e) {}
}

const initialState: AppState = {
  dataView: DataView.UI,
  isEditForm: false,
  openOverlays: [],
  showLoadingAnimation: false,
  tracker: InitialTracker,
  userPermissions: InitialUserPermissions,
  userSettings: getInitialUserSettings(),
  userViewContext: UserViewContext.Loading,
}

/*
 * State slice defined by the name with reducers and the initial state
 * (see https://redux-toolkit.js.org/api/createSlice)
 */

export const appState = createSlice({
  initialState,
  name: 'app',
  reducers: {
    setDataView: (state, action: PayloadAction<DataView>) => {
      state.dataView = action.payload
    },
    setIsEditForm: (state, action: PayloadAction<boolean>) => {
      state.isEditForm = action.payload
    },
    setOverlay: (state, action: PayloadAction<{ overlay: ViewOverlay; isOpen: boolean }>) => {
      const { isOpen, overlay } = action.payload
      if (isOpen) {
        if (!state.openOverlays.includes(overlay)) {
          state.openOverlays.push(overlay)
        }
      } else {
        state.openOverlays = state.openOverlays.filter((item) => item !== overlay)
      }
    },
    setShowLoadingAnimation: (state, action: PayloadAction<boolean>) => {
      state.showLoadingAnimation = action.payload
    },
    setTracker: (state, action: PayloadAction<Tracker>) => {
      state.tracker = action.payload
    },
    setUserPermissions: (state, action: PayloadAction<UserPermissions>) => {
      state.userPermissions = action.payload
    },
    setUserSettings: (state, action: PayloadAction<UserSettings>) => {
      setInitialUserSettings(action.payload)
      state.userSettings = action.payload
    },
    setUserViewContext: (state, action: PayloadAction<UserViewContext>) => {
      state.userViewContext = action.payload
    },
  },
})

/*
 * Actions
 */

export const appActions = (dispatch: Dispatch) => ({
  setDataView: (values: DataView) => dispatch(appState.actions.setDataView(values)),
  setIsEditForm: (values: boolean) => dispatch(appState.actions.setIsEditForm(values)),
  setOverlay: (values: { overlay: ViewOverlay; isOpen: boolean }) =>
    dispatch(appState.actions.setOverlay(values)),
  setShowLoadingAnimation: (values: boolean) =>
    dispatch(appState.actions.setShowLoadingAnimation(values)),
  setTracker: (values: Tracker) => dispatch(appState.actions.setTracker(values)),
  setUserPermissions: (values: UserPermissions) => dispatch(appState.actions.setUserPermissions(values)),
  setUserSettings: (values: UserSettings) => dispatch(appState.actions.setUserSettings(values)),
  setUserViewContext: (values: UserViewContext) => dispatch(appState.actions.setUserViewContext(values)),
})

// 👉 Preferred way to use actions
export const useAppActions = () => {
  const dispatch = useDispatch()
  return appActions(dispatch)
}

/*
 * Selectors
 */

type State = { app: AppState }

export const appSelectors = {
  dataView: (state: State) => state.app.dataView,
  isEditForm: (state: State) => state.app.isEditForm,
  openOverlays: (state: State) => state.app.openOverlays,
  showLoadingAnimation: (state: State) => state.app.showLoadingAnimation,
  tracker: (state: State) => state.app.tracker,
  userPermissions: (state: State) => state.app.userPermissions,
  userSettings: (state: State) => state.app.userSettings,
  userViewContext: (state: State) => state.app.userViewContext,
}
