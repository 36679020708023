import { UserPermission } from './user.types'

// App config types

export type AppConfig = {
  azure: {
    appInsightsConnectionString: string
    clientId: string
    tenantId: string
    userPermissionGroups: Record<string, UserPermission>
  }
  api: {
    apiEnvironment: string
    appServicePath: string
    bobBackendApiPath: string
    internalDashboardServicePath: string
    partnerPortalServicePath: string
  }
  app: {
    customerPortalWebAppUrl: string
    dashboardEnvironment: string
    dashboardLoginBackgroundImage: string
    dashboardWebAppUrl: string
    whitelabelWebAppUrl: string
  }
  data: {
    refetchListEnabled: boolean
    refetchListInterval: number
    refetchDetailEnabled: boolean
    refetchDetailInterval: number
  }
  docuWare: {
    loginUrl: string
    webAppUrl: string
  }
  loan: {
    dfsWebAppUrl: string
    mambuWebAppUrl: string
  }
  locize: {
    apiKey: string
    productId: string
    version: string
  }
}

// Pagination types
export type Pagination = {
  currentPage: number
  numResults: number
  numPages: number
}

// Data view types
export enum DataView {
  Raw = 'Raw',
  UI = 'UI',
}

// View overlay
export enum ViewOverlay {
  CustomerDetails = 'Customer Details',
  FinancingDetails = 'Financing Details',
  PartnerDetails = 'Partner Details',
  PartnerPortalFinancingDetails = 'Partner Portal - Financing Details',
}
