import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { useAppConfig, useTracker } from 'app/data/app.data'
import { AppConfig } from 'app/types/app.types'
import {
  AppInsights_TrackProperties,
  TrackEventData,
  TrackException,
  TrackMissingTranslation,
  Tracker,
} from 'app/types/tracker.types'
import { ReactNode, useEffect } from 'react'
import styled from 'styled-components'

/*
 * Load application
 */
const reactPlugin = new ReactPlugin()

const loadAppInsights = (appConfig: AppConfig): ApplicationInsights | null => {
  const hasAppInsightsTracking =
    appConfig.app.dashboardEnvironment !== 'development' && !!appConfig.azure.appInsightsConnectionString

  let appInsights: ApplicationInsights | null = null

  if (hasAppInsightsTracking) {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: appConfig.azure.appInsightsConnectionString,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {},
        },
        disableFetchTracking: false,
        enableAjaxErrorStatusText: true,
        autoTrackPageVisitTime: true,
      },
    })

    appInsights.loadAppInsights()
  }

  return appInsights
}

const MissingKeys = new Set<string>()

/*
 * Prepare properties for tracker
 */
const toPascalCase = (str: string) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => {
    return chr.toUpperCase()
  })
}
const getTrackProperties = (params: TrackEventData): AppInsights_TrackProperties => {
  return {
    errors: params.errors,
    language: params.language,
    additionalTrackProps: params.additionalData,
  }
}

/*
 * App Insights Tracker Wrapper
 */

const getAppInsightsTraceId = (appInsights: ApplicationInsights) =>
  appInsights.context.telemetryTrace?.traceID ?? null

const getAppInsightsTracker = ({
  appConfig,
  appInsights,
}: {
  appConfig: Pick<AppConfig, 'app'>
  appInsights: ApplicationInsights | null
}): Tracker => ({
  hasAppInsightsTracking: !!appConfig,
  isInitialized: true,
  operationId: () => (appInsights ? getAppInsightsTraceId(appInsights) : null),

  trackEvent: (params: { name: string } & TrackEventData) => {
    const { additionalTrackProps, ...trackProps } = getTrackProperties(params)
    if (appInsights && appConfig.app.dashboardEnvironment !== 'development') {
      appInsights.trackEvent({ name: params.name }, { ...trackProps, ...additionalTrackProps })
      if (appConfig.app.dashboardEnvironment !== 'production') {
        console.debug('### APP INSIGHTS - TRACK EVENT ###')
        console.debug('-> OperationIId', getAppInsightsTraceId(appInsights))
      }
    } else {
      console.debug('### APP INSIGHTS - TRACK EVENT ###')
      console.debug('Name', params.name)
      console.debug('Properties', JSON.stringify(trackProps, null, 2))
      console.debug('Additional Properties', JSON.stringify(additionalTrackProps, null, 2))
      console.debug('')
    }
  },

  // Track exception
  trackException: (payload: Omit<TrackException, 'type'>) => {
    const exceptionData = {
      exception: payload.exception,
      customProperties: payload.customProperties,
      severityLevel: payload.severity,
    }
    if (appInsights && appConfig.app.dashboardEnvironment !== 'development') {
      appInsights.trackException(exceptionData)
      if (appConfig.app.dashboardEnvironment !== 'production') {
        console.debug('### APP INSIGHTS - TRACK EXCEPTION ###')
        console.debug('-> OperationIId', getAppInsightsTraceId(appInsights))
      }
    } else {
      console.debug('### APP INSIGHTS - TRACK EXCEPTION ###')
      console.debug('ExceptionData', JSON.stringify(exceptionData, null, 2))
    }
  },

  // Track missing translation
  trackMissingTranslation: (payload: TrackMissingTranslation) => {
    if (!MissingKeys.has(`${payload.ns}.${payload.key}`)) {
      const missingTranslationWarning = {
        name: 'Missing Translation',
        message: `Key ${payload.key} in namespace ${payload.ns} missing`,
        Key: payload.key,
        Namespace: payload.ns,
        Language: payload.language,
      }

      if (appInsights && appConfig.app.dashboardEnvironment !== 'development') {
        appInsights.trackException({
          exception: missingTranslationWarning,
          severityLevel: SeverityLevel.Warning,
        })
        if (appConfig.app.dashboardEnvironment !== 'production') {
          console.debug('### APP INSIGHTS - TRACK MISSING TRANSLATION WARNING ###')
          console.debug('-> OperationIId', getAppInsightsTraceId(appInsights))
        }
      } else {
        console.debug('### APP INSIGHTS - TRACK MISSING TRANSLATION WARNING ###')
        console.debug('Missing Translation', JSON.stringify(missingTranslationWarning, null, 2))
      }

      MissingKeys.add(`${payload.ns}.${payload.key}`)
    }
  },
})

/*
 * AppInsights Context with React plugin
 */

export const AppInsightsTrackingComponent = withAITracking(
  reactPlugin,
  ({ children }: { children: ReactNode }) => {
    return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
  },
  'AppInsightsTrackingComponent',
)

/*
 * Tracking provider
 */

export const TrackingProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { setTracker, tracker } = useTracker()

  const hasTracker = appConfig.azure.appInsightsConnectionString

  useEffect(() => {
    if (hasTracker) {
      const appInsights = loadAppInsights(appConfig)
      setTracker(getAppInsightsTracker({ appConfig, appInsights }))
    }
  }, [])

  return (
    <>
      {tracker.hasAppInsightsTracking && tracker.isInitialized ? (
        <AppInsightsTrackingComponent>
          <TrackingContent>{props.children}</TrackingContent>
        </AppInsightsTrackingComponent>
      ) : tracker.isInitialized ? (
        props.children
      ) : !hasTracker ? (
        props.children
      ) : null}
    </>
  )
}

// This is required because withAITracking adds an empty div to the DOM
const TrackingContent = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`
