import { useAppConfig } from 'app/data/app.data'
import { useUserPermissions, useUserViewContext } from 'app/data/user.data'
import { UserViewContext } from 'app/types/user.types'
import { Suspense, lazy, useEffect } from 'react'
import { LoadingPage } from 'shared/components/layout/LoadingPage'

const InternalDashboardApp = lazy(() => import('internalDashboard/view/InternalDashboardApp'))
const PartnerPortalApp = lazy(() => import('partnerPortal/view/PartnerPortalApp'))

const Loading = () => <LoadingPage />
// const LoadingPage = () => <></>

export const UserContextRouterProvider = () => {
  const { appConfig } = useAppConfig()
  const { hasDeveloperPermission } = useUserPermissions()
  const { userViewContext } = useUserViewContext()

  // Add key listener to body and list for Cmd+Shift+U to switch between local dev environment (http://localhost:3000) and test or stage environments
  useEffect(() => {
    const hasEnvSwitch =
      ['test', 'stage'].includes(appConfig.api.apiEnvironment) &&
      appConfig.azure.userPermissionGroups &&
      hasDeveloperPermission
    const handleKeyDown = (event: KeyboardEvent) => {
      if (hasEnvSwitch && event.code === 'KeyU' && event.ctrlKey && event.shiftKey) {
        const location = window.location.href.includes('localhost')
          ? window.location.href.replace(
              'http://localhost:3000',
              `https://dashboard.${appConfig.api.apiEnvironment}.bob.ch`,
            )
          : window.location.href.replace(
              `https://dashboard.${appConfig.api.apiEnvironment}.bob.ch`,
              'http://localhost:3000',
            )

        window.open(location, '_blank')
      }
    }

    hasEnvSwitch && document.body.addEventListener('keydown', handleKeyDown)
    return () => (hasEnvSwitch ? document.body.removeEventListener('keydown', handleKeyDown) : undefined)
  }, [hasDeveloperPermission])

  return (
    <Suspense fallback={<Loading />}>
      {userViewContext === UserViewContext.InternalDashboard ? (
        <InternalDashboardApp />
      ) : (
        <PartnerPortalApp />
      )}
    </Suspense>
  )
}
