/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionPermissionsDTO } from '../models/ActionPermissionsDTO';
import type { MeDTO } from '../models/MeDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GeneralService {

    /**
     * Get the current user.
     * @returns MeDTO
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<MeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me',
        });
    }

    /**
     * Get all action permissions.
     * @returns ActionPermissionsDTO
     * @throws ApiError
     */
    public static getActionPermissions(): CancelablePromise<Array<ActionPermissionsDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/actionPermissions',
        });
    }

}
